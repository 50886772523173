import axios from 'axios';
import { errorHandler } from 'errorHandler';
import { stringify } from 'query-string';
import { CREATE, GET_LIST, GET_MANY, GET_ONE, UPDATE } from 'react-admin';

export const challengeCategory = async (
    type: string,
    params: any,
    resource: string
) => {
    try {
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: field,
                    order,
                    page,
                    perPage
                };
                const url = `/admin/challenge-categories?${stringify(query)}`;
                const {
                    data: { items, total }
                } = await axios.get(url);
                return {
                    data: items.map((x) => ({ ...x, visible: !x.is_hidden })),
                    total: total
                };
            }
            case GET_ONE: {
                const rez = await axios.get(
                    `/admin/challenge-categories/${params.id}`
                );
                return {
                    data: {
                        ...rez.data,
                        visible: !rez.data.is_hidden,
                        image: {
                            id: rez.data.image_id,
                            url: rez.data.image_url
                        }
                    }
                };
            }
            case GET_MANY: {
                const rez = await Promise.all(
                    params.ids.map(async (idx) => {
                        const rez = await axios.get(
                            `/admin/challenge-categories/${idx}`
                        );

                        return rez.data;
                    })
                );

                return {
                    data: rez
                };
            }
            case CREATE: {
                const {
                    visible,
                    order_nr,
                    title_lt,
                    title_en,
                    description_lt,
                    description_en,
                    is_primary,
                    image
                } = params.data;

                let image_id: string;
                if (image) {
                    const formData = new FormData();
                    formData.append('file', image.rawFile);
                    formData.append('type', 'challenge_category');

                    const res = await axios.post('/storage/file', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    });
                    image_id = res.data.id;
                }

                const { data } = await axios.post(
                    '/admin/challenge-categories',
                    {
                        is_hidden: !visible,
                        order_nr,
                        title_lt,
                        title_en,
                        description_en,
                        description_lt,
                        is_primary,
                        image_id
                    }
                );
                return {
                    data: {
                        ...data,
                        visible: !data.is_hidden
                    }
                };
            }
            case UPDATE: {
                const {
                    visible,
                    order_nr,
                    title_lt,
                    title_en,
                    description_lt,
                    description_en,
                    is_primary,
                    image,
                    id
                } = params.data;

                let newImageId: string = image?.id || undefined;
                if (image?.rawFile instanceof File) {
                    const formData = new FormData();
                    formData.append('file', image.rawFile);
                    formData.append('type', 'challenge_category');

                    const res = await axios.post('/storage/file', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    });
                    newImageId = res.data.id;
                }

                await axios.put(`/admin/challenge-categories/${id}`, {
                    is_hidden: !visible,
                    order_nr,
                    title_lt,
                    title_en,
                    description_en,
                    description_lt,
                    is_primary,
                    image_id: newImageId,
                    id
                });

                return { data: params };
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
    } catch (error) {
        errorHandler(error);
    }
};
