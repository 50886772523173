import React, { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import UnpublishIcon from '@material-ui/icons/Restore';
import axios from 'axios';
import { Button, Confirm, useNotify, useRefresh } from 'react-admin';

interface IOwnProps {
    record?: any;
}

export const NotificationPublishButton = ({ record }: IOwnProps) => {
    const { id, published_at } = record!;

    const [isLoading, setIsLoading] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();

    const handlePublish = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setIsLoading(true);
        e.stopPropagation();
        try {
            await axios.post(`/admin/notifications/custom/${id}/publish`);
            notify(`Notification published successfully`);
            setIsLoading(false);
            refresh();
        } catch (err) {
            notify('Failed to publish the notification');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUnpublish = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setIsLoading(true);
        e.stopPropagation();
        try {
            await axios.post(`/admin/notifications/custom/${id}/unpublish`);
            notify(`Notification unpublished successfully`);
            setIsLoading(false);
            refresh();
        } catch (err) {
            notify('Failed to unpublish the notification');
        } finally {
            setIsLoading(false);
        }
    };

    const openConfirmationModal = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.stopPropagation();
        setConfirmationModalOpen(true);
    };

    return (
        <Box alignItems='center' display='flex'>
            {isLoading ? (
                <CircularProgress />
            ) : published_at ? (
                <Box>
                    <Button
                        label='Unpublish'
                        color='secondary'
                        variant='contained'
                        onClick={openConfirmationModal}
                    >
                        <UnpublishIcon />
                    </Button>
                    <Confirm
                        isOpen={confirmationModalOpen}
                        title={`Unpublish the notification`}
                        content={`Are you sure you want to unpublish this custom notification? The notification will be deleted from all notification feeds.`}
                        onConfirm={handleUnpublish}
                        onClose={() => setConfirmationModalOpen(false)}
                    />
                </Box>
            ) : (
                <Box>
                    <Button
                        label='Publish'
                        color='secondary'
                        variant='contained'
                        onClick={openConfirmationModal}
                    >
                        <PublishIcon />
                    </Button>
                    <Confirm
                        isOpen={confirmationModalOpen}
                        title={`Publish the notification`}
                        content={`Are you sure you want to publish this custom notification? It will be pushed to all users.`}
                        onConfirm={handlePublish}
                        onClose={() => setConfirmationModalOpen(false)}
                    />
                </Box>
            )}
        </Box>
    );
};
