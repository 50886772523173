import React, { useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    TextField as MaterialTextField
} from '@material-ui/core';
import axios from 'axios';
import { useNotify, useRefresh } from 'react-admin';

export const IncreaseQuantity = ({ id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const refresh = useRefresh();
    const notify = useNotify();

    const handleChange = (event) => {
        setQuantity(parseInt(event.target.value));
    };

    const increaseQuantity = () => {
        setIsLoading(true);
        axios
            .post(`/admin/prizes/${id}/increase-stock`, {
                quantity: quantity
            })
            .then(() => {
                notify(`Prize stock increased by ${quantity}`);
                setIsLoading(false);
                refresh();
            });
    };

    return (
        <Box alignItems='center' display='flex'>
            <MaterialTextField
                style={{ marginRight: '30px' }}
                onChange={handleChange}
                value={quantity}
                variant='filled'
                id='quantity'
                name='quantity'
                type='number'
                label='Quantity to add'
            />
            <Button
                color='primary'
                variant='contained'
                onClick={increaseQuantity}
            >
                Add
            </Button>
            {isLoading && <CircularProgress />}
        </Box>
    );
};
