import axios from 'axios';
import { stringify } from 'query-string';
import {
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    DELETE_MANY,
    GET_LIST,
    GET_MANY
} from 'react-admin';

export const group = async (type: string, params: any, resource: string) => {
    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: field,
                order,
                page,
                perPage
            };
            const url = `/${resource}?${stringify(query)}`;
            const {
                data: { items, total }
            } = await axios.get(url);
            return {
                data: items,
                total: total
            };
        }
        case GET_ONE: {
            try {
                const {
                    data: { id, name }
                } = await axios.get(`/group/${params.id}`);
                const {
                    data: { groupPermissions, availablePermissions }
                } = await axios.get(`/group-permission/${id}`);

                return {
                    data: {
                        id,
                        name,
                        permissions: groupPermissions,
                        availablepermissions: availablePermissions
                    }
                };
            } catch (err) {
                throw new Error('Server error');
            }
        }
        case GET_MANY: {
            try {
                const rez = await Promise.all(
                    params.ids.map(async (idx) => {
                        const {
                            data: { id, name }
                        } = await axios.get(`/group/${idx}`);

                        return {
                            id: id,
                            name: name
                        };
                    })
                );

                return {
                    data: rez
                };
            } catch (err) {
                throw new Error('Server error');
            }
        }
        case UPDATE: {
            try {
                const { id, name, newPermissions } = params.data;
                let permissions =
                    newPermissions && newPermissions.map((p) => p.value);
                if (newPermissions === null) permissions = [];
                await axios.patch(`/group/${id}`, { permissions, name });

                return { data: params };
            } catch (err) {
                throw new Error('Server error');
            }
        }
        case CREATE: {
            try {
                const { name } = params.data;
                const { data } = await axios.post('/group', { name });
                return { data };
            } catch (err) {
                throw new Error('Server error');
            }
        }
        case DELETE: {
            const { id } = params;
            const { data } = await axios.delete(`/group/${id}`);
            return { data };
        }
        case DELETE_MANY: {
            const { ids } = params;
            await axios.delete(`/group`, { data: { ids } });
            return { data: ids };
        }
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};
