import * as React from 'react';
import {
    Show as ShowComp,
    List as ListComp,
    Edit as EditComp,
    EditButton,
    SimpleShowLayout,
    RichTextField,
    Datagrid,
    TextField,
    SimpleForm,
    Toolbar,
    SaveButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Typography, withStyles } from '@material-ui/core';

export const List = (props) => {
    return (
        <ListComp
            title='About Vibelift'
            exporter={false}
            {...props}
            bulkActionButtons={false}
        >
            <Datagrid rowClick='show'>
                <TextField source='language' />
                {props.permissions && props.permissions['admin_write'] ? (
                    <EditButton />
                ) : null}
            </Datagrid>
        </ListComp>
    );
};

const EditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton label='Save' redirect='show' submitOnEnter={true} />
    </Toolbar>
);

const EditTitle = (props) => {
    return (
        <span>
            Edit About Vibelift {`(${props.record.language.toUpperCase()})`}
        </span>
    );
};

// The styles are needed to fix a bug with link popup being cut off by the editor boundaries
// https://github.com/marmelab/react-admin/issues/2125
export const Edit: React.FC<any> = withStyles({
    card: { overflow: 'initial' }
})((props) => (
    <EditComp title={<EditTitle />} {...props}>
        <SimpleForm toolbar={<EditToolbar />}>
            <Typography
                variant='h5'
                component='h2'
                style={{
                    width: '100%',
                    textAlign: 'center'
                }}
            >
                About page editor
            </Typography>
            <RichTextInput
                source='content'
                label={false}
                toolbar={[
                    ['bold', 'italic', 'underline', 'link'],

                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ direction: 'rtl' }],

                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ size: ['small', false, 'large', 'huge'] }],

                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ['image'],
                    ['clean']
                ]}
            />
        </SimpleForm>
    </EditComp>
)) as any;

const ShowTitle = (props) => {
    return (
        <span>About Vibelift {`(${props.record.language.toUpperCase()})`}</span>
    );
};

export const Show = (props) => {
    return (
        <ShowComp title={<ShowTitle />} {...props}>
            <SimpleShowLayout className='ql-snow'>
                <RichTextField source='content' className='ql-editor' />
            </SimpleShowLayout>
        </ShowComp>
    );
};
