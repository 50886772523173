import React from 'react';

import styled from 'styled-components';
import {
    List as ListComp,
    Show as ShowComp,
    Create as CreateComp,
    Datagrid,
    TextField,
    SimpleShowLayout,
    SimpleForm,
    EditButton,
    TextInput,
    required,
    Toolbar,
    SaveButton,
    Filter,
    ImageField,
    ImageInput
} from 'react-admin';

import EditComp from 'UI/Molecules/EditForm';
import { SectionTitle } from '../helpers';

const H1 = styled.h1`
    && {
        width: 100%;
        text-align: center;
    }
`;

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label='Search' source='search' alwaysOn />
    </Filter>
);

export const List = (props) => {
    return (
        <ListComp filters={<Filters />} {...props} bulkActionButtons={false}>
            <Datagrid rowClick='show'>
                <TextField source='key' />
                <TextField source='value' />

                {props.permissions && props.permissions['edit_user'] ? (
                    <EditButton />
                ) : null}
            </Datagrid>
        </ListComp>
    );
};

const validateSettingCreation = (values) => {
    const errors = {} as any;
    if (!values.value) {
        errors.value = ['Must have value'];
    }
    if (!values.key) {
        errors.key = ['Must have value'];
    }

    if (values.value === 'site_logo' && !values.image) {
        errors.image = ['Please, select file to upload'];
    }

    return errors;
};

const EditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton label='Save' redirect='show' submitOnEnter={true} />
    </Toolbar>
);

export const Edit = (props) => {
    return (
        <EditComp props={props}>
            <SimpleForm
                validate={validateSettingCreation}
                toolbar={<EditToolbar />}
            >
                <H1>Edit Setting</H1>
                <TextField source='key' />
                <TextInput label='Value' source='value' validate={required()} />

                {
                    <ImageInput
                        source='image'
                        label='Upload Image'
                        accept='image/*'
                    >
                        <ImageField source='imageUrl' title='user-avatar' />
                    </ImageInput>
                }
            </SimpleForm>
        </EditComp>
    );
};

export const Create = (props) => (
    <CreateComp {...props} redirect='list'>
        <SimpleForm validate={validateSettingCreation} redirect='show'>
            <TextInput label='Key' source='key' validate={required()} />
            <TextInput label='Value' source='value' validate={required()} />
        </SimpleForm>
    </CreateComp>
);

export const Show = (props) => {
    return (
        <ShowComp title={<SectionTitle action='User' />} {...props}>
            <SimpleShowLayout>
                <TextField source='key' />
                <TextField source='value' />
            </SimpleShowLayout>
        </ShowComp>
    );
};
