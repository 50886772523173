import React from 'react';
import {
    List as ListComp,
    Show as ShowComp,
    Create as CreateComp,
    Edit as EditComp,
    Datagrid,
    TextField,
    EditButton,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    required,
    RichTextField,
    DateField
} from 'react-admin';
import EditToolbar from 'UI/Molecules/EditToolbar';
import RichTextInput from 'ra-input-rich-text';
import { Box, makeStyles, Typography, withStyles } from '@material-ui/core';
import { NewsPublishButton } from './NewsPublishButton';

export const List = (props) => (
    <ListComp
        exporter={false}
        {...props}
        bulkActionButtons={false}
        sort={{ field: 'published_at', order: 'DESC' }}
    >
        <Datagrid rowClick='show'>
            <TextField label='Title (EN)' source='title_en' />
            <TextField source='status' />
            <DateField source='published_at' locales='lt-LT' />

            {props.permissions && props.permissions['admin_write'] ? (
                <EditButton />
            ) : null}
            {props.permissions && props.permissions['admin_write'] ? (
                <NewsPublishButton />
            ) : null}
        </Datagrid>
    </ListComp>
);

const useStyles = makeStyles({
    h1: {
        width: '100%',
        textAlign: 'center'
    },
    contentPreview: {
        padding: 0
    }
});

const ShowTitle = (props) => {
    return <span>News {`"${props.record.title_en}"`}</span>;
};

export const Show = (props) => {
    const styles = useStyles();
    return (
        <ShowComp title={<ShowTitle />} {...props}>
            <SimpleShowLayout className='ql-snow'>
                <TextField source='id' />
                <TextField source='status' />
                <DateField source='published_at' locales='lt-LT' />

                <TextField source='title_en' label='Title (EN)' />
                <RichTextField
                    source='content_en'
                    className={`ql-editor ${styles.contentPreview}`}
                    label='News Content (EN)'
                />

                <TextField source='title_lt' label='Title (LT)' />
                <RichTextField
                    source='content_lt'
                    className={`ql-editor ${styles.contentPreview}`}
                    label='News Content (LT)'
                />
            </SimpleShowLayout>
        </ShowComp>
    );
};

const editorToolbarConfig = [
    ['bold', 'italic', 'underline', 'link'],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ['small', false, 'large', 'huge'] }],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['image'],
    ['clean']
];

export const Create = (props) => {
    const styles = useStyles();

    return (
        <CreateComp {...props}>
            <SimpleForm redirect='show'>
                <TextInput
                    label='Title (EN)'
                    source='title_en'
                    validate={required()}
                    fullWidth={true}
                />
                <Typography variant='h5' component='h2' className={styles.h1}>
                    News Content editor (EN)
                </Typography>

                <RichTextInput
                    source='content_en'
                    label={false}
                    toolbar={editorToolbarConfig}
                />

                <Box paddingTop={4} />

                <TextInput
                    label='Title (LT)'
                    source='title_lt'
                    validate={required()}
                    fullWidth={true}
                />
                <Typography variant='h5' component='h2' className={styles.h1}>
                    News Content editor (LT)
                </Typography>
                <RichTextInput
                    source='content_lt'
                    label={false}
                    toolbar={editorToolbarConfig}
                />
            </SimpleForm>
        </CreateComp>
    );
};

const EditTitle = (props) => {
    return <span>Edit news {`"${props.record.title_en}"`}</span>;
};

export const Edit: React.FC<any> = withStyles({
    card: { overflow: 'initial' }
})((props) => {
    const styles = useStyles();

    return (
        <EditComp title={<EditTitle />} {...props}>
            <SimpleForm
                toolbar={
                    <EditToolbar
                        getDeleteConfirmContent={(record) =>
                            `Are you sure you want to delete this item?${
                                record.status === 'published'
                                    ? ' The news notification will be removed as well!'
                                    : ''
                            }`
                        }
                        getDeleteConfirmTitle={(record) =>
                            `Delete news "${record.title_en}"`
                        }
                    />
                }
            >
                <TextInput
                    label='Title (EN)'
                    source='title_en'
                    validate={required()}
                    fullWidth={true}
                />
                <Typography variant='h5' component='h2' className={styles.h1}>
                    News Content editor (EN)
                </Typography>

                <RichTextInput
                    source='content_en'
                    label={false}
                    toolbar={editorToolbarConfig}
                />

                <Box paddingTop={4} />

                <TextInput
                    label='Title (LT)'
                    source='title_lt'
                    validate={required()}
                    fullWidth={true}
                />
                <Typography variant='h5' component='h2' className={styles.h1}>
                    News Content editor (LT)
                </Typography>
                <RichTextInput
                    source='content_lt'
                    label={false}
                    toolbar={editorToolbarConfig}
                />
            </SimpleForm>
        </EditComp>
    );
}) as any;
