import React from 'react';
import styled from 'styled-components';
import { Edit } from 'react-admin';
import { SectionTitle } from 'pages/helpers';

// TODO: This is supposedly needed for the custom select input to display properly, but it does not seem to anyways?
const StyledEdit = styled(Edit)`
    & > div {
        overflow: unset;
    }
`;

const CustomEdit = ({ children, title = undefined, props }) => {
    return (
        <StyledEdit
            title={title || <SectionTitle action='Edit' />}
            undoable={false}
            {...props}
        >
            {children}
        </StyledEdit>
    );
};

export default CustomEdit;
