import axios from 'axios';
import { errorHandler } from 'errorHandler';
import { stringify } from 'query-string';
import { GET_LIST, GET_ONE, UPDATE } from 'react-admin';

export const challengeProposal = async (
    type: string,
    params: any,
    resource: string
) => {
    try {
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: field,
                    order,
                    page,
                    perPage
                };
                const url = `/admin/challenges/user-proposed?${stringify(
                    query
                )}`;
                const {
                    data: { items, total }
                } = await axios.get(url);
                return {
                    data: items.map((x) => ({ ...x, visible: !x.is_hidden })),
                    total: total
                };
            }
            case GET_ONE: {
                const rez = await axios.get(
                    `/admin/challenges/user-proposed/${params.id}`
                );
                return {
                    data: {
                        ...rez.data,
                        visible: !rez.data.is_hidden,
                        image: {
                            id: rez.data.image_id,
                            url: rez.data.image_url
                        }
                    }
                };
            }
            case UPDATE: {
                const {
                    title_lt,
                    title_en,
                    video_url,
                    is_featured,
                    participation_points,
                    win_points,
                    description_lt,
                    description_en,
                    order_nr,
                    visible,
                    image,
                    deadline,
                    id,
                    status
                } = params.data;

                let newImageId = image?.id;
                // If the file has changed
                if (image?.rawFile instanceof File) {
                    const formData = new FormData();
                    formData.append('file', image.rawFile);
                    formData.append('type', 'challenge');

                    const res = await axios.post('/storage/file', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    });
                    newImageId = res.data.id;
                }

                await axios.put(`/admin/challenges/user-proposed/${id}`, {
                    title_lt,
                    title_en,
                    video_url,
                    win_points,
                    participation_points,
                    description_en,
                    description_lt,
                    order_nr,
                    is_hidden: !visible,
                    is_featured,
                    image_id: newImageId,
                    deadline,
                    id,
                    status
                });
                return { data: params };
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
    } catch (error) {
        errorHandler(error);
    }
};
