import axios from 'axios';
import { stringify } from 'query-string';
import {
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    DELETE_MANY,
    GET_LIST
} from 'react-admin';

export const permission = async (
    type: string,
    params: any,
    resource: string
) => {
    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: field,
                order,
                page,
                perPage
            };
            const url = `/${resource}?${stringify(query)}`;
            const {
                data: { items, total }
            } = await axios.get(url);
            return {
                data: items,
                total: total
            };
        }
        case GET_ONE: {
            try {
                const rez = await axios.get(`/permission/${params.id}`);
                return rez;
            } catch (err) {
                throw new Error('Server error');
            }
        }
        case UPDATE: {
            try {
                const { id, name } = params.data;
                await axios.put(`/permission/${id}`, { name });
                return { data: params };
            } catch (err) {
                throw new Error('Server error');
            }
        }
        case CREATE: {
            try {
                const { name } = params.data;
                const { data } = await axios.post('/permission', { name });
                return { data };
            } catch (err) {
                throw new Error('Server error');
            }
        }
        case DELETE: {
            const { id } = params;
            const { data } = await axios.delete(`/permission/${id}`);
            return { data };
        }
        case DELETE_MANY: {
            const { ids } = params;
            await axios.delete(`/permission`, { data: { ids } });
            return { data: ids };
        }
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};
