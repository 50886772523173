import React from 'react';

interface IOwnProps {
    action: string;
    record?: any;
}

export const SectionTitle = ({ action, record }: IOwnProps) => {
    return (
        <span>
            {action}{' '}
            {record ? `"${record.email ? record.email : record.id}"` : ''}
        </span>
    );
};
