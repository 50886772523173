import React from 'react';
import {
    List as ListComp,
    Show as ShowComp,
    Create as CreateComp,
    Datagrid,
    TextField,
    EmailField,
    SimpleShowLayout,
    SimpleForm,
    EditButton,
    TextInput,
    email,
    required,
    minLength,
    Filter,
    ReferenceInput,
    SelectInput as ReactAdminSelectInput,
    BooleanField,
    FormDataConsumer
} from 'react-admin';
import CustomList from '../../UI/Atoms/List';
import EditComp from 'UI/Molecules/EditForm';
import { SectionTitle } from '../helpers';
import { BlockToggleButton } from './BlockToggleButton';
import { Box, Typography } from '@material-ui/core';
import EditToolbar from 'UI/Molecules/EditToolbar';

const validateUserCreation = (values) => {
    const errors = {} as any;
    if (values.confirmPassword !== values.newPassword) {
        errors.confirmPassword = ['Passwords must match'];
    }
    return errors;
};

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label='Search' source='search' alwaysOn />
    </Filter>
);

export const List = (props) => (
    <ListComp
        filters={<Filters />}
        {...props}
        bulkActionButtons={false}
        exporter={false}
    >
        <Datagrid rowClick='show'>
            <TextField source='name' />
            <EmailField source='email' />
            <TextField source='groupNames' label='Groups' />
            <BooleanField source='is_email_verified' label='Email verified' />
            <BooleanField source='blocked' />

            {props.permissions && props.permissions['admin_write'] ? (
                <BlockToggleButton />
            ) : null}

            {props.permissions && props.permissions['admin_write'] ? (
                <EditButton />
            ) : null}
        </Datagrid>
    </ListComp>
);

const ShowTitle = (props) => {
    return <span>User {`"${props.record.name}"`}</span>;
};

export const Show = (props) => {
    return (
        <ShowComp title={<ShowTitle />} {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <TextField source='name' />
                <EmailField source='email' />
                <BooleanField
                    source='is_email_verified'
                    label='Email verified'
                />
                <CustomList source='groups' />
                <CustomList source='permissions' />
            </SimpleShowLayout>
        </ShowComp>
    );
};

const validateEmail = [required(), email()];
const validatePassword = [
    required(),
    minLength(8, 'Password should be at least 8 symbols long!'),
    (val: string) =>
        /[a-z]+/.test(val)
            ? ''
            : 'Password must contain at least one lowercase letter!',
    (val: string) =>
        /[A-Z]+/.test(val)
            ? ''
            : 'Password must contain at least one uppercase letter!',
    (val: string) =>
        /\d+/.test(val) ? '' : 'Password must contain at least one digit!',
    (val: string) =>
        /\W+/.test(val)
            ? ''
            : 'Password must contain at least one symbol (*, _ etc)!'
];

const EditTitle = (props) => {
    return <span>Edit user {`"${props.record.name}"`}</span>;
};

export const Edit = (props) => {
    return (
        <EditComp title={<EditTitle />} props={props}>
            <SimpleForm
                validate={validateUserCreation}
                toolbar={
                    <EditToolbar
                        getDeleteConfirmContent={(record) =>
                            `Are you sure you want delete this user's account? This action cannot be undone, and all records related to the user (posts, challenge proposals, notifications etc.) will be deleted as well.`
                        }
                        getDeleteConfirmTitle={(record) =>
                            `Delete user "${record.name}"`
                        }
                    />
                }
            >
                <Box margin='auto'>
                    <Typography component='h1' variant='h5' align='center'>
                        User Info
                    </Typography>
                </Box>

                <TextInput label='Name' source='name' validate={required()} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <TextInput
                            source='email'
                            validate={validateEmail}
                            // No editing if it's a simple user
                            disabled={formData.groups?.length === 0}
                            {...rest}
                        />
                    )}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <TextInput
                            {...rest}
                            label='New Password'
                            source='newPassword'
                            type='password'
                            validate={
                                formData.groups?.length !== 0 &&
                                validatePassword
                            }
                            // No editing if it's a simple user
                            disabled={formData.groups?.length === 0}
                        />
                    )}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <TextInput
                            label='Confirm New Password'
                            source='confirmPassword'
                            type='password'
                            validate={
                                formData.groups?.length !== 0 &&
                                validatePassword
                            }
                            // No editing if it's a simple user
                            disabled={formData.groups?.length === 0}
                            {...rest}
                        />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </EditComp>
    );
};

export const Create = (props) => (
    <CreateComp {...props} redirect='list'>
        <SimpleForm validate={validateUserCreation} redirect='show'>
            <TextInput label='Name' source='name' validate={required()} />
            <TextInput
                label='Email'
                source='email'
                type='email'
                validate={validateEmail}
            />
            <TextInput
                label='Password'
                source='newPassword'
                type='password'
                validate={validatePassword}
            />
            <TextInput
                label='Confirm Password'
                source='confirmPassword'
                type='password'
                validate={validatePassword}
            />
            <ReferenceInput
                reference='group'
                source='group_id'
                validate={required()}
            >
                <ReactAdminSelectInput />
            </ReferenceInput>
        </SimpleForm>
    </CreateComp>
);
