import React from 'react';
import styled from 'styled-components';
import ReactSelectField from '../Atoms/ReactSelectField';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 200px;
`;
const Title = styled.span`
    margin-top: 15px;
    font-weight: 700;
`;

interface IOwnProps {
    source: string;
    record?: any;
    title: string;
    label: string;
    paramsName: string;
}
const SelectInput = ({
    source,
    record,
    title,
    label,
    paramsName
}: IOwnProps) => {
    const selected = Array.isArray(record[source])
        ? transformArray(record[source])
        : record[source];
    const sourceName = `available${source}`;
    const available = Array.isArray(record[sourceName])
        ? transformArray(record[sourceName])
        : record[sourceName];

    return available ? (
        <Wrapper>
            <Title>{title}</Title>
            <ReactSelectField
                label={label}
                source={paramsName}
                options={available}
                selected={selected}
                isMulti
                closeMenuOnSelect={false}
            />
        </Wrapper>
    ) : null;
};

const transformArray = (array) => {
    const newArray = array.map((item) => ({
        label: item.name,
        value: item.id
    }));

    return newArray;
};

export default SelectInput;
