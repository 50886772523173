import axios from 'axios';
import { errorHandler } from 'errorHandler';
import { GET_ONE, UPDATE } from 'react-admin';

export const contactInfo = async (
    type: string,
    params: any,
    resource: string
) => {
    try {
        switch (type) {
            case GET_ONE: {
                const rez = await axios.get(`/admin/contact-info/`);
                return {
                    data: { id: 1, ...rez.data }
                };
            }
            case UPDATE: {
                const {
                    company_name,
                    phone_number,
                    email,
                    address_short_lt,
                    address_short_en,
                    address_long_lt,
                    address_long_en,
                    coordinates,
                    facebook_name,
                    instagram_name,
                    tiktok_name
                } = params.data;
                await axios.put(`/admin/contact-info`, {
                    company_name,
                    phone_number,
                    email,
                    address_short_lt,
                    address_short_en,
                    address_long_lt,
                    address_long_en,
                    coordinates,
                    facebook_name,
                    instagram_name,
                    tiktok_name
                });
                return {
                    data: params
                };
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
    } catch (error) {
        errorHandler(error);
    }
};
