import * as React from 'react';
import {
    Show as ShowComp,
    List as ListComp,
    Edit as EditComp,
    EditButton,
    SimpleShowLayout,
    RichTextField,
    Datagrid,
    TextField,
    SimpleForm,
    Toolbar,
    SaveButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Box, makeStyles, Typography, withStyles } from '@material-ui/core';

export const List = (props) => {
    return (
        <ListComp
            title='Policies & Terms'
            exporter={false}
            {...props}
            bulkActionButtons={false}
        >
            <Datagrid rowClick='show'>
                <TextField source='language' />
                {props.permissions && props.permissions['admin_write'] ? (
                    <EditButton />
                ) : null}
            </Datagrid>
        </ListComp>
    );
};

const EditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton label='Save' redirect='show' submitOnEnter={true} />
    </Toolbar>
);

const useStyles = makeStyles({
    h1: {
        width: '100%',
        textAlign: 'center'
    }
});

const EditTitle = (props) => {
    return (
        <span>
            Edit Policies & Terms {`(${props.record.language.toUpperCase()})`}
        </span>
    );
};

// The styles are needed to fix a bug with link popup being cut off by the editor boundaries
// https://github.com/marmelab/react-admin/issues/2125
export const Edit: React.FC<any> = withStyles({
    card: { overflow: 'initial' }
})((props) => {
    const styles = useStyles();
    const toolbarConfig = [
        ['bold', 'italic', 'underline', 'link'],

        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],

        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ size: ['small', false, 'large', 'huge'] }],

        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['clean']
    ];

    return (
        <EditComp title={<EditTitle />} {...props}>
            <SimpleForm toolbar={<EditToolbar />}>
                <Typography variant='h5' component='h2' className={styles.h1}>
                    Privacy Policy editor
                </Typography>
                <RichTextInput
                    source='privacy_policy_content'
                    label={false}
                    toolbar={toolbarConfig}
                />

                <Box paddingTop={4} />

                <Typography variant='h5' component='h2' className={styles.h1}>
                    Terms of Use editor
                </Typography>
                <RichTextInput
                    source='terms_of_use_content'
                    label={false}
                    toolbar={toolbarConfig}
                />
            </SimpleForm>
        </EditComp>
    );
}) as any;

const ShowTitle = (props) => {
    return (
        <span>
            Policies & Terms {`(${props.record.language.toUpperCase()})`}
        </span>
    );
};

export const Show = (props) => {
    return (
        <ShowComp title={<ShowTitle />} {...props}>
            <SimpleShowLayout className='ql-snow'>
                <RichTextField
                    source='privacy_policy_content'
                    className='ql-editor'
                    label='Privacy Policy content'
                />
                <RichTextField
                    source='terms_of_use_content'
                    className='ql-editor'
                    label='Terms of Use content'
                />
            </SimpleShowLayout>
        </ShowComp>
    );
};
