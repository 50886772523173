import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 16px;
`;

const useStyles = makeStyles({
    subTitle: {
        lineHeight: '16px',
        paddingBottom: '8px'
    }
});

interface IOwnProps {
    source: string;
    record?: any;
}

const CustomList = ({ source, record }: IOwnProps) => {
    const classes = useStyles();
    const list = record[source];
    return list && list.length > 0 ? (
        <Box>
            <Typography
                variant='body1'
                component='h3'
                className={classes.subTitle}
            >
                {source.charAt(0).toUpperCase() + source.slice(1)}
            </Typography>
            <List>
                <Typography variant='body1'>
                    {list.map((item) => (
                        <li key={item.id}>{item.name}</li>
                    ))}
                </Typography>
            </List>
        </Box>
    ) : null;
};

export default CustomList;
