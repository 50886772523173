import axios from 'axios';
import { errorHandler } from 'errorHandler';
import { stringify } from 'query-string';
import { GET_LIST, GET_ONE } from 'react-admin';

export const pointPurchase = async (
    type: string,
    params: any,
    resource: string
) => {
    try {
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: field,
                    order,
                    page,
                    perPage
                };
                const url = `/admin/point-purchases?${stringify(query)}`;
                const {
                    data: { items, total }
                } = await axios.get(url);
                return {
                    data: items,
                    total: total
                };
            }
            case GET_ONE: {
                const rez = await axios.get(
                    `/admin/point-purchases/${params.id}`
                );
                return {
                    data: rez.data
                };
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
    } catch (error) {
        errorHandler(error);
    }
};
