import React, { Fragment, useState } from 'react';
import {
    List as ListComp,
    Show as ShowComp,
    Datagrid,
    TextField,
    SimpleShowLayout,
    ReferenceField,
    DateField,
    DeleteWithConfirmButton,
    ImageField,
    UrlField,
    TopToolbar,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    Button,
    Confirm,
    useNotify,
    useRefresh,
    BooleanField,
    TextInput
} from 'react-admin';
import { Favorite } from '@material-ui/icons';
import axios from 'axios';

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label='User ID' source='user_id' reference='users' />
        <ReferenceInput label='Challenge name' source='challenge_id' reference='challenges' perPage={1000}>
            <AutocompleteInput
                optionText={(choice) => `${choice.title_en || 'any'}`}
            />
        </ReferenceInput>
    </Filter>
);

export const List = (props) => (
    <ListComp
        {...props}
        bulkActionButtons={false}
        sort={{ field: 'created_at', order: 'DESC' }}
        filters={<PostFilter />}
    >
        <Datagrid rowClick='show'>
            <ImageField source='image_url' label='Post image' />
            <ReferenceField source='user_id' reference='users' link='show'>
                <TextField source='name' />
            </ReferenceField>
            <ReferenceField
                source='challenge_id'
                reference='challenges'
                link='show'
            >
                <TextField source='title_en' />
            </ReferenceField>
            <DateField source='created_at' showTime />

            {props.permissions && props.permissions['admin_write'] ? (
                <DeleteWithConfirmButton
                    record={props.record}
                    basePath={props.basePath}
                />
            ) : null}
        </Datagrid>
    </ListComp>
);

const ChooseWinnerButton = ({ record }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async () => {
        try {
            setLoading(true);
            await axios.post(
                `/admin/challenges/${record.challenge_id}/won-post/${record.id}`
            );
            notify('Challenge winner chosen');
            refresh();
        } catch (err) {
            if (err.response.status === 409) {
                notify(err.response.data.error.message, 'error');
            }
        }
        setLoading(false);
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label='Choose as winner' onClick={handleClick}>
                <Favorite />
            </Button>
            <Confirm
                isOpen={open}
                title='Choose post as challenge winner'
                content='Are you sure you want to choose this post as the winner?'
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                loading={loading}
            />
        </Fragment>
    );
};

const PostShowActions = ({ basePath, data, resource, permissions }: any) => (
    <TopToolbar>
        {permissions && permissions['admin_write'] ? (
            <ChooseWinnerButton record={data} />
        ) : null}
        {permissions && permissions['admin_write'] ? (
            <DeleteWithConfirmButton record={data} basePath={basePath} />
        ) : null}
    </TopToolbar>
);

export const Show = (props) => (
    <ShowComp
        {...props}
        actions={<PostShowActions permissions={props.permissions} />}
    >
        <SimpleShowLayout>
            <ImageField source='image_url' label='Post image' />
            <TextField source='id' />
            <DateField source='created_at' showTime />
            <ReferenceField source='user_id' reference='users' link='show'>
                <TextField source='name' />
            </ReferenceField>
            <ReferenceField
                source='challenge_id'
                reference='challenges'
                link='show'
            >
                <TextField source='title_en' />
            </ReferenceField>
            <BooleanField source='won_challenge' />
            <UrlField source='image_url' />
        </SimpleShowLayout>
    </ShowComp>
);
