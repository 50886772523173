import React from 'react';
import inflection from 'inflection';
import { getResources } from 'ra-core';
import DefaultIcon from '@material-ui/icons/ViewList';
import { DashboardMenuItem, MenuItemLink } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';

export const Menu = ({
    onMenuClick,
    logout,
    hasDashboard,
    translate,
    dense,
    ...rest
}) => {
    const isXSmall = useMediaQuery((theme: any) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);

    return (
        <div {...rest}>
            {hasDashboard && (
                <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            )}
            {resources
                .filter((r) => r.hasList || r.hasShow)
                .map((resource) => (
                    <MenuItemLink
                        key={resource.name}
                        // If a resource only has show, it's assumed that it will always be singular and will be returned with the id of 1 from the resource provider
                        to={
                            resource.hasList
                                ? `/${resource.name}`
                                : `/${resource.name}/1/show`
                        }
                        primaryText={
                            (resource.options && resource.options.label) ||
                            inflection.humanize(
                                inflection.pluralize(resource.name)
                            )
                        }
                        leftIcon={
                            resource.icon ? <resource.icon /> : <DefaultIcon />
                        }
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                ))}
            {isXSmall && logout}
        </div>
    );
};
