import React from 'react';
import { ImageInput } from 'react-admin';

const ImagePreviewField = ({ source, record = {} }) => {
    return (
        record && (
            <img
                style={{ maxWidth: '150px', height: 'auto' }}
                src={record[source]}
            />
        )
    );
};

interface IOwnProps {
    multiple: boolean;
    source: string;
    label: string;
    imagePreviewSource: string;
    imageRequired?: boolean;
    imageInputClasses?: any;
}

export const ImageUpload = ({
    multiple,
    source,
    label,
    imagePreviewSource,
    imageRequired,
    imageInputClasses
}: IOwnProps) => {
    return (
        <ImageInput
            multiple={multiple}
            source={source}
            label={label}
            accept='image/*'
            maxSize={1024 * 1024 * 20}
            placeholder={<p>Drop your file here</p>}
            validate={(val) => {
                return imageRequired && !val ? 'An image is required' : '';
            }}
            classes={imageInputClasses}
        >
            <ImagePreviewField source={imagePreviewSource} />
        </ImageInput>
    );
};
