import { makeStyles } from '@material-ui/core';
import React from 'react';
import {
    List as ListComp,
    Show as ShowComp,
    Create as CreateComp,
    Datagrid,
    TextField,
    EditButton,
    BooleanField,
    NumberField,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    BooleanInput,
    ImageField
} from 'react-admin';
import EditComp from 'UI/Molecules/EditForm';
import EditToolbar from 'UI/Molecules/EditToolbar';
import { ImageUpload } from 'UI/Molecules/ImageUpload';

export const List = (props) => (
    <ListComp {...props} bulkActionButtons={false}>
        <Datagrid rowClick='show'>
            <TextField label='Title (EN)' source='title_en' />
            <TextField label='Title (LT)' source='title_lt' />
            <NumberField source='order_nr' label='Order' />
            <BooleanField source='visible' />
            <BooleanField source='is_primary' label='Primary' />

            {props.permissions && props.permissions['admin_write'] ? (
                <EditButton />
            ) : null}
        </Datagrid>
    </ListComp>
);

const useImageShowStyles = makeStyles({
    imageField: {
        '& img': {
            border: '1px solid rgba(224, 224, 224, 0.6)',
            backgroundColor: 'rgba(224, 224, 224, 0.6)'
        }
    }
});

const ShowTitle = (props) => {
    return <span>Challenge category {`"${props.record.title_en}"`}</span>;
};

export const Show = (props) => {
    const styles = useImageShowStyles();
    return (
        <ShowComp title={<ShowTitle />} {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <TextField source='title_en' />
                <TextField source='title_lt' />
                <TextField source='description_en' />
                <TextField source='description_lt' />
                <NumberField source='order_nr' label='Order' />
                <BooleanField source='visible' />
                <BooleanField source='is_primary' label='Primary' />
                <ImageField
                    source='image_url'
                    label='Category icon'
                    className={styles.imageField}
                />
            </SimpleShowLayout>
        </ShowComp>
    );
};

const useImageUploadStyles = makeStyles({
    root: {
        '& img': {
            border: '1px solid rgba(224, 224, 224, 0.6)',
            backgroundColor: 'rgba(224, 224, 224, 0.6)'
        }
    }
});

export const Create = (props) => {
    const classes = useImageUploadStyles();
    return (
        <CreateComp {...props}>
            <SimpleForm redirect='show'>
                <TextInput
                    label='Title (EN)'
                    source='title_en'
                    validate={required()}
                />
                <TextInput
                    label='Title (LT)'
                    source='title_lt'
                    validate={required()}
                />
                <TextInput
                    label='Description (EN)'
                    source='description_en'
                    parse={(value) => value}
                />
                <TextInput
                    label='Description (LT)'
                    source='description_lt'
                    parse={(value) => value}
                />
                <NumberInput
                    label='Order'
                    source='order_nr'
                    validate={required()}
                />
                <BooleanInput source='visible' defaultValue={true} />
                <BooleanInput
                    source='is_primary'
                    label='Primary'
                    defaultValue={false}
                />
                <ImageUpload
                    source='image'
                    multiple={false}
                    imageRequired={false}
                    label='Category icon'
                    imagePreviewSource='url'
                    imageInputClasses={classes}
                />
            </SimpleForm>
        </CreateComp>
    );
};

const EditTitle = (props) => {
    return <span>Edit challenge category {`"${props.record.title_en}"`}</span>;
};

export const Edit = (props) => {
    const classes = useImageUploadStyles();
    return (
        <EditComp title={<EditTitle />} props={props}>
            <SimpleForm toolbar={<EditToolbar withDelete={false} />}>
                <TextInput
                    label='Title (EN)'
                    source='title_en'
                    validate={required()}
                />
                <TextInput
                    label='Title (LT)'
                    source='title_lt'
                    validate={required()}
                />
                <TextInput
                    label='Description (EN)'
                    source='description_en'
                    parse={(value) => value} // This is so that '' instead of null is sent to dataProvied
                />
                <TextInput
                    label='Description (LT)'
                    source='description_lt'
                    parse={(value) => value}
                />
                <NumberInput
                    label='Order'
                    source='order_nr'
                    validate={required()}
                />
                <BooleanInput source='visible' defaultValue={true} />
                <BooleanInput
                    source='is_primary'
                    label='Primary'
                    defaultValue={false}
                />
                <ImageUpload
                    source='image'
                    multiple={false}
                    imageRequired={false}
                    label='Category icon'
                    imagePreviewSource='url'
                    imageInputClasses={classes}
                />
            </SimpleForm>
        </EditComp>
    );
};
