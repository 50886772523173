import * as React from 'react';
import {
    Show as ShowComp,
    Edit as EditComp,
    SimpleShowLayout,
    TextField,
    SimpleForm,
    Toolbar,
    SaveButton,
    EmailField,
    TextInput,
    UrlField
} from 'react-admin';

const EditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton label='Save' redirect='show' submitOnEnter={true} />
    </Toolbar>
);

export const Edit = (props) => (
    <EditComp title='Edit contacts' {...props}>
        <SimpleForm toolbar={<EditToolbar />}>
            <TextInput source='company_name' />
            <TextInput source='phone_number' />
            <TextInput source='email' />
            <TextInput source='address_short_lt' label='Address short (LT)' />
            <TextInput source='address_short_en' label='Address short (EN)' />
            <TextInput source='address_long_lt' label='Address long (LT)' />
            <TextInput source='address_long_en' label='Address long (EN)' />
            <TextInput
                source='coordinates'
                helperText='Format: 41.40338, 2.17403'
                validate={(val: string) =>
                    /^\d+(\.\d+)?\s*,\s*\d+(\.\d+)?\s*$/.test(val)
                        ? ''
                        : 'The coordinates must match this format: 41.40338, 2.17403'
                }
            />
            <TextInput source='facebook_name' />
            <TextInput source='instagram_name' />
            <TextInput source='tiktok_name' />
        </SimpleForm>
    </EditComp>
);

export const Show = (props) => {
    return (
        <ShowComp title='Contacts' {...props}>
            <SimpleShowLayout>
                <TextField source='company_name' />
                <TextField source='phone_number' />
                <EmailField source='email' />
                <TextField
                    source='address_short_lt'
                    label='Address short (LT)'
                />
                <TextField
                    source='address_short_en'
                    label='Address short (EN)'
                />
                <TextField source='address_long_lt' label='Address long (LT)' />
                <TextField source='address_long_en' label='Address long (EN)' />
                <TextField source='coordinates' />
                <TextField source='facebook_name' />
                <TextField source='instagram_name' />
                <TextField source='tiktok_name' />
            </SimpleShowLayout>
        </ShowComp>
    );
};
