import axios from 'axios';
import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_ERROR,
    AUTH_CHECK,
    AUTH_GET_PERMISSIONS
} from 'react-admin';
import { removeLocalUser } from 'Helpers/localUser';

export default async (type: string, params: any) => {
    if (type === AUTH_LOGIN) {
        const { isAuth } = params;

        return isAuth ? Promise.resolve() : Promise.reject();
    }
    if (type === AUTH_LOGOUT) {
        removeLocalUser();
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('id');

        var config = {
            headers: { Authorization: token }
        };
        const {
            data: { id }
        } = await axios.get(`/user/verify`, config);

        return userId === id ? Promise.resolve() : Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const permissions = JSON.parse(localStorage.getItem('permissions'));
        return Promise.resolve(permissions);
    }
    return Promise.reject('Unknown method');
};
