import React, { Component } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import Wrapper from 'UI/Layouts/Auth';
import { LoginForm } from 'UI/Organisms';
import { setLocalUser } from 'Helpers/localUser';

interface IOwnProps {
    userLogin: any;
}

class Login extends Component<IOwnProps> {
    submit = async (values, actions) => {
        actions.setSubmitting(true);
        try {
            const {
                data: { id, email, token }
            } = await axios.post(`/user/login`, values);
            const permissions = await this.getPermissions(token);
            // Only allow admins to log in
            if (!permissions['admin_read']) {
                throw {
                    response: {
                        status: 401
                    }
                };
            }
            setLocalUser(id, email, token, permissions);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            this.props.userLogin({ isAuth: true });
            actions.setSubmitting(false);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                actions.setErrors({ password: 'Invalid credentials' });
            } else actions.setErrors({ password: 'Network error' });
            actions.setSubmitting(false);
        }
    };

    getPermissions = async (token) => {
        const {
            data: { permissions }
        } = await axios.get(`/user/permissions`, {
            headers: { Authorization: 'Bearer ' + token }
        });
        const permissionsObject = {};
        permissions.forEach((p) => {
            permissionsObject[p] = p;
        });
        return permissionsObject;
    };

    render() {
        const schema = Yup.object().shape({
            email: Yup.string().email().required(),
            password: Yup.string().required()
        });
        return (
            <Wrapper title='Login'>
                <LoginForm
                    initialValues={{ email: '', password: '' }}
                    onSubmit={this.submit}
                    validationSchema={schema}
                />
            </Wrapper>
        );
    }
}

export default connect(undefined, { userLogin })(Login);
