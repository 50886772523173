import Button from '@material-ui/core/Button';
import React from 'react';
import {
    List as ListComp,
    Show as ShowComp,
    Create as CreateComp,
    Datagrid,
    TextField,
    EditButton,
    BooleanField,
    NumberField,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    BooleanInput,
    ReferenceField,
    DateField,
    UrlField,
    DateInput,
    ReferenceInput,
    SelectInput,
    TopToolbar,
    ImageField
} from 'react-admin';
import EditComp from 'UI/Molecules/EditForm';
import EditToolbar from 'UI/Molecules/EditToolbar';
import { Link } from 'react-router-dom';
import { NavigateNext } from '@material-ui/icons';
import { ImageUpload } from 'UI/Molecules/ImageUpload';
import { Typography } from '@material-ui/core';
import { validateUrl } from 'utils';

export const List = (props) => (
    <ListComp {...props} bulkActionButtons={false}>
        <Datagrid rowClick='show'>
            <TextField source='title_en' label='Title (EN)' />
            <DateField source='deadline' />
            <NumberField source='win_points' />
            <NumberField source='participation_points' />
            <BooleanField source='is_featured' label='Featured' />
            <BooleanField source='visible' />
            <ReferenceField
                source='challenge_category_id'
                reference='challenge_categories'
            >
                <TextField source='title_en' />
            </ReferenceField>

            {props.permissions && props.permissions['admin_write'] ? (
                <EditButton />
            ) : null}
        </Datagrid>
    </ListComp>
);

const RelatedPostsButton = ({ record }) => {
    return record ? (
        <Button
            label='Go to related posts'
            color='primary'
            component={Link}
            to={{
                pathname: '/posts',
                search: `filter=${JSON.stringify({ challenge_id: record.id })}`
            }}
            style={{ margin: 'auto 0px' }}
        >
            Related Posts
            <NavigateNext />
        </Button>
    ) : null;
};

const ChallengeShowActions = ({
    basePath,
    data,
    resource,
    permissions
}: any) => (
    <TopToolbar>
        <RelatedPostsButton record={data} />
        {permissions && permissions['admin_write'] ? (
            <EditButton
                record={data}
                basePath={basePath}
                style={{ margin: 'auto 0px' }}
            />
        ) : null}
    </TopToolbar>
);

const ShowTitle = (props) => {
    return <span>Challenge {`"${props.record.title_en}"`}</span>;
};

export const Show = (props) => (
    <ShowComp
        title={<ShowTitle />}
        {...props}
        actions={<ChallengeShowActions permissions={props.permissions} />}
    >
        <SimpleShowLayout>
            <TextField source='id' />
            <TextField source='title_en' label='Title (EN)' />
            <TextField source='title_lt' label='Title (LT)' />
            <TextField source='slogan_en' label='Slogan (EN)' />
            <TextField source='slogan_lt' label='Slogan (LT)' />
            <DateField source='deadline' />
            <NumberField source='win_points' />
            <NumberField source='participation_points' />
            <UrlField source='video_url' />
            <BooleanField source='is_featured' label='Featured' />
            <BooleanField source='visible' />
            <NumberField source='order_nr' label='Order' />
            <ReferenceField
                source='challenge_category_id'
                reference='challenge_categories'
            >
                <TextField source='title_en' />
            </ReferenceField>
            <ReferenceField source='won_post_id' reference='posts' link='show'>
                <TextField source='id' />
            </ReferenceField>
            <ReferenceField
                source='proposer_id'
                reference='users'
                label='Proposer'
                link='show'
            >
                <TextField source='name' />
            </ReferenceField>
            <ImageField source='image_url' label='Challenge image' />
        </SimpleShowLayout>
    </ShowComp>
);

export const Create = (props) => (
    <CreateComp {...props}>
        <SimpleForm redirect='show'>
            <TextInput
                source='title_en'
                label='Title (EN)'
                validate={required()}
            />
            <TextInput
                source='title_lt'
                label='Title (LT)'
                validate={required()}
            />
            <TextInput
                source='slogan_en'
                label='Slogan (EN)'
                validate={required()}
            />
            <TextInput
                source='slogan_lt'
                label='Slogan (LT)'
                validate={required()}
            />
            <DateInput source='deadline' validate={required()} />
            <NumberInput source='win_points' validate={required()} />
            <NumberInput source='participation_points' validate={required()} />
            <TextInput source='video_url' type='url' validate={validateUrl} />
            <BooleanInput
                source='is_featured'
                label='Featured'
                defaultValue={false}
            />
            <NumberInput
                source='order_nr'
                label='Order'
                validate={required()}
            />
            <ReferenceInput
                source='challenge_category_id'
                reference='challenge_categories'
                allowEmpty
            >
                <SelectInput optionText='title_en' />
            </ReferenceInput>
            <ImageUpload
                source='image'
                multiple={false}
                imageRequired={true}
                label='Challenge image'
                imagePreviewSource='url'
            />
        </SimpleForm>
    </CreateComp>
);

const EditTitle = (props) => {
    return <span>Edit challenge {`"${props.record.title_en}"`}</span>;
};

export const Edit = (props) => (
    <EditComp title={<EditTitle />} props={props}>
        <SimpleForm toolbar={<EditToolbar withDelete={false} />}>
            <TextInput
                source='title_en'
                label='Title (EN)'
                validate={required()}
            />
            <TextInput
                source='title_lt'
                label='Title (LT)'
                validate={required()}
            />
            <TextInput
                source='slogan_en'
                label='Slogan (EN)'
                validate={required()}
            />
            <TextInput
                source='slogan_lt'
                label='Slogan (LT)'
                validate={required()}
            />
            <DateInput source='deadline' validate={required()} />
            <NumberField source='win_points' />
            <NumberField source='participation_points' />
            <TextInput source='video_url' type='url' validate={validateUrl} />
            <BooleanInput
                source='is_featured'
                label='Featured'
                defaultValue={false}
            />
            <Typography variant='caption' component='div' color='error'>
                If the visibility is changed from hidden to visible, a
                notification about a new challenge will be pushed to all users!
            </Typography>
            <BooleanInput source='visible' defaultValue={true} />
            <NumberInput
                source='order_nr'
                label='Order'
                validate={required()}
            />
            <ReferenceInput
                source='challenge_category_id'
                reference='challenge_categories'
                allowEmpty
            >
                <SelectInput optionText='title_en' />
            </ReferenceInput>
            <ImageUpload
                source='image'
                multiple={false}
                imageRequired={true}
                label='Challenge image'
                imagePreviewSource='url'
            />
        </SimpleForm>
    </EditComp>
);
