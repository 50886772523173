import React from 'react';
import {
    List as ListComp,
    Show as ShowComp,
    Create as CreateComp,
    Edit as EditComp,
    Datagrid,
    TextField,
    EditButton,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    required,
    DateField,
    DeleteWithConfirmButton,
    TopToolbar
} from 'react-admin';
import EditToolbar from 'UI/Molecules/EditToolbar';
import { NotificationPublishButton } from './NotificationPublishButton';

export const NotificationListMainActionButton = (props) => {
    return props.record.published_at ? (
        <DeleteWithConfirmButton
            {...props}
            confirmTitle={`Delete notification #${props.record.id}`}
            confirmContent='Are you sure you want to delete this notification? The notification will be deleted from all notification feeds.'
        />
    ) : (
        <EditButton {...props} />
    );
};

export const List = (props) => (
    <ListComp
        title='Notifications'
        exporter={false}
        {...props}
        bulkActionButtons={false}
        sort={{ field: 'published_at', order: 'DESC' }}
    >
        <Datagrid rowClick='show'>
            <TextField label='Content (EN)' source='content_en' />
            <DateField
                source='created_at'
                label='Created at'
                locales='lt-LT'
                style={{ whiteSpace: 'nowrap' }}
            />
            <DateField
                source='published_at'
                label='Published at'
                locales='lt-LT'
            />

            {props.permissions && props.permissions['admin_write'] ? (
                <NotificationListMainActionButton />
            ) : null}
            {props.permissions && props.permissions['admin_write'] ? (
                <NotificationPublishButton />
            ) : null}
        </Datagrid>
    </ListComp>
);

const NotificationShowActions = ({
    basePath,
    data,
    resource,
    permissions
}: any) => (
    <TopToolbar>
        {permissions && permissions['admin_write'] && !data?.published_at ? (
            <EditButton record={data} basePath={basePath} />
        ) : null}
    </TopToolbar>
);

const ShowTitle = (props) => {
    return <span>Notification {`#${props.record.id}`}</span>;
};

export const Show = (props) => {
    return (
        <ShowComp
            title={<ShowTitle />}
            {...props}
            actions={
                <NotificationShowActions permissions={props.permissions} />
            }
        >
            <SimpleShowLayout>
                <TextField source='id' />
                <DateField source='created_at' locales='lt-LT' />
                <TextField source='content_en' label='Content (EN)' />
                <TextField source='content_lt' label='Content (LT)' />
            </SimpleShowLayout>
        </ShowComp>
    );
};

export const Create = (props) => (
    <CreateComp {...props}>
        <SimpleForm redirect='show'>
            <TextInput
                label='Content (EN)'
                source='content_en'
                validate={required()}
                fullWidth={true}
            />
            <TextInput
                label='Content (LT)'
                source='content_lt'
                validate={required()}
                fullWidth={true}
            />
        </SimpleForm>
    </CreateComp>
);

const EditTitle = (props) => {
    return <span>Edit notification {`#${props.record.id}`}</span>;
};

export const Edit: React.FC<any> = (props) => (
    <EditComp title={<EditTitle />} {...props}>
        <SimpleForm
            toolbar={
                <EditToolbar
                    getDeleteConfirmTitle={(record) =>
                        `Delete notification #${record.id}`
                    }
                />
            }
        >
            <TextInput
                label='Content (EN)'
                source='content_en'
                validate={required()}
                fullWidth={true}
            />
            <TextInput
                label='Content (LT)'
                source='content_lt'
                validate={required()}
                fullWidth={true}
            />
        </SimpleForm>
    </EditComp>
);
