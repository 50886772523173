import React from 'react';
import {
    List as ListComp,
    Show as ShowComp,
    Datagrid,
    TextField,
    EditButton,
    BooleanField,
    NumberField,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    BooleanInput,
    ReferenceField,
    DateField,
    UrlField,
    DateInput,
    SelectInput,
    TopToolbar,
    ImageField
} from 'react-admin';
import EditComp from 'UI/Molecules/EditForm';
import EditToolbar from 'UI/Molecules/EditToolbar';
import { ImageUpload } from 'UI/Molecules/ImageUpload';
import { Typography } from '@material-ui/core';
import { validateUrl } from 'utils';

const ChallengeProposalMainActionButton = (props) => {
    return props.record.status === 'proposed' ? (
        <EditButton {...props} />
    ) : null;
};

export const List = (props) => (
    <ListComp {...props} bulkActionButtons={false}>
        <Datagrid rowClick='show'>
            <TextField source='title_en' label='Title (EN)' />
            <DateField source='deadline' />
            <NumberField source='win_points' />
            <NumberField source='participation_points' />
            <BooleanField source='is_featured' label='Featured' />
            <BooleanField source='visible' />
            <TextField source='status' />

            {props.permissions && props.permissions['admin_write'] ? (
                <ChallengeProposalMainActionButton />
            ) : null}
        </Datagrid>
    </ListComp>
);

const ShowActions = ({ basePath, data, resource, permissions }: any) => (
    <TopToolbar>
        {permissions &&
        permissions['admin_write'] &&
        data.status === 'proposed' ? (
            <EditButton
                record={data}
                basePath={basePath}
                style={{ margin: 'auto 0px' }}
            />
        ) : null}
    </TopToolbar>
);

const ShowTitle = (props) => {
    return <span>Challenge proposal {`"${props.record.title_en}"`}</span>;
};

export const Show = (props) => (
    <ShowComp
        title={<ShowTitle />}
        {...props}
        actions={<ShowActions permissions={props.permissions} />}
    >
        <SimpleShowLayout>
            <TextField source='id' />
            <TextField source='title_en' label='Title (EN)' />
            <TextField source='title_lt' label='Title (LT)' />
            <TextField source='description_en' label='Description (EN)' />
            <TextField source='description_lt' label='Description (LT)' />
            <DateField source='deadline' />
            <UrlField source='video_url' />
            <TextField source='status' />

            <NumberField source='win_points' />
            <NumberField source='participation_points' />
            <BooleanField source='is_featured' label='Featured' />
            <BooleanField source='visible' />
            <NumberField source='order_nr' label='Order' />
            <ReferenceField
                source='proposer_id'
                reference='users'
                label='Proposer'
                link='show'
            >
                <TextField source='name' />
            </ReferenceField>
            <ReferenceField
                source='challenge_id'
                reference='challenges'
                link='show'
            >
                <TextField source='id' />
            </ReferenceField>
            <ImageField source='image_url' label='Challenge image' />
        </SimpleShowLayout>
    </ShowComp>
);

const EditTitle = (props) => {
    return <span>Edit challenge proposal {`"${props.record.title_en}"`}</span>;
};

export const Edit = (props) => (
    <EditComp title={<EditTitle />} props={props}>
        <SimpleForm toolbar={<EditToolbar withDelete={false} />}>
            <TextInput
                source='title_en'
                label='Title (EN)'
                validate={required()}
            />
            <TextInput
                source='title_lt'
                label='Title (LT)'
                validate={required()}
            />
            <TextInput
                source='description_en'
                label='Description (EN)'
                validate={required()}
            />
            <TextInput
                source='description_lt'
                label='Description (LT)'
                validate={required()}
            />
            <DateInput source='deadline' validate={required()} />
            <TextInput source='video_url' type='url' validate={validateUrl} />
            <Typography variant='caption' component='div' color='error'>
                A proposal can no longer be edited after it has been approved or
                rejected!
            </Typography>
            <SelectInput
                source='status'
                choices={[
                    { id: 'proposed', name: 'proposed' },
                    { id: 'rejected', name: 'rejected' },
                    { id: 'approved', name: 'approved' }
                ]}
            />

            <NumberInput source='win_points' validate={required()} />
            <NumberInput source='participation_points' validate={required()} />
            <BooleanInput
                source='is_featured'
                label='Featured'
                defaultValue={false}
            />
            <Typography variant='caption' component='div' color='error'>
                If the proposal's visibility is set to visible and it is
                approved, a notification about a new challenge will be pushed to
                all users!
            </Typography>
            <BooleanInput source='visible' defaultValue={false} />
            <NumberInput
                source='order_nr'
                label='Order'
                validate={required()}
            />
            <ImageUpload
                source='image'
                multiple={false}
                imageRequired={true}
                label='Challenge image'
                imagePreviewSource='url'
            />
        </SimpleForm>
    </EditComp>
);
