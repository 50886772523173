import axios from 'axios';
import { stringify } from 'query-string';
import { DELETE, GET_LIST, GET_MANY, GET_ONE } from 'react-admin';

export const post = async (type: string, params: any, resource: string) => {
    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: field,
                order,
                page,
                perPage,
                ...params.filter
            };
            const url = `/admin/posts/?${stringify(query)}`;
            const {
                data: { items, total }
            } = await axios.get(url);
            return {
                data: items,
                total: total
            };
        }
        case GET_ONE: {
            try {
                const rez = await axios.get(`/admin/posts/${params.id}`);
                return {
                    data: rez.data
                };
            } catch (err) {
                throw new Error('Server error');
            }
        }
        case GET_MANY: {
            try {
                const rez = await Promise.all(
                    params.ids.map(async (idx) => {
                        const rez = await axios.get(`/posts/${idx}`);

                        return rez.data;
                    })
                );

                return {
                    data: rez
                };
            } catch (err) {
                throw new Error('Server error');
            }
        }
        case DELETE: {
            const { id } = params;
            await axios.delete(`/posts/${id}`);
            return { data: {} };
        }
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};
