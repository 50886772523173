import axios from 'axios';
import { errorHandler } from 'errorHandler';
import { stringify } from 'query-string';
import {
    CREATE,
    DELETE,
    GET_LIST,
    GET_MANY,
    GET_ONE,
    UPDATE
} from 'react-admin';

export const prizes = async (type: string, params: any, resource: string) => {
    try {
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: field,
                    order,
                    page,
                    perPage
                };
                const url = `/admin/prizes?${stringify(query)}`;
                const {
                    data: { items, total }
                } = await axios.get(url);
                return {
                    data: items,
                    total: total
                };
            }
            case GET_ONE: {
                const { data } = await axios.get(`/admin/prizes/${params.id}`);
                return {
                    data
                };
            }
            case GET_MANY: {
                const rez = await Promise.all(
                    params.ids.map(async (idx) => {
                        const rez = await axios.get(`/admin/prizes/${idx}`);

                        return rez.data;
                    })
                );

                return {
                    data: rez
                };
            }
            case CREATE: {
                const newPictures = params.data.images.filter(
                    (p) => p.rawFile instanceof File
                );

                const uploadResults = await Promise.all(
                    newPictures.map(async (f, idx) => {
                        const formData = new FormData();
                        formData.append('file', f.rawFile);
                        formData.append('type', 'profile');

                        const res = await axios.post(
                            '/storage/file',
                            formData,
                            {
                                headers: {
                                    'content-type': 'multipart/form-data'
                                }
                            }
                        );
                        return {
                            id: res.data.id,
                            url: res.data.mediumImageUrl,
                            priority: idx
                        };
                    })
                );

                const { data } = await axios.post('/admin/prizes', {
                    ...params.data,
                    images: uploadResults
                });
                return {
                    data: data
                };
            }
            case UPDATE: {
                const { id } = params.data;

                // Map before filter to get the correct priority values
                const newPictures = params.data.images
                    .map((x, idx) => ({ ...x, priority: idx }))
                    .filter((p) => p.rawFile instanceof File);
                const formerPictures = params.data.images
                    .map((x, idx) => ({ ...x, priority: idx }))
                    .filter((p) => !(p.rawFile instanceof File));

                const uploadResults = await Promise.all(
                    newPictures.map(async (f) => {
                        const formData = new FormData();
                        formData.append('file', f.rawFile);
                        formData.append('type', 'prize');

                        const res = await axios.post(
                            '/storage/file',
                            formData,
                            {
                                headers: {
                                    'content-type': 'multipart/form-data'
                                }
                            }
                        );
                        return {
                            id: res.data.id,
                            url: res.data.mediumImageUrl,
                            priority: f.priority
                        };
                    })
                );

                await axios.put(`/admin/prizes/${id}`, {
                    ...params.data,
                    images: [...formerPictures, ...uploadResults]
                });

                return { data: params.data };
            }
            case DELETE: {
                const { id } = params;
                await axios.delete(`/admin/prizes/${id}`);
                return {
                    data: params.previousData
                };
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
    } catch (error) {
        errorHandler(error);
    }
};
