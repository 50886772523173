import React from 'react';
import {
    List as ListComp,
    Show as ShowComp,
    Datagrid,
    TextField,
    BooleanField,
    NumberField,
    SimpleShowLayout,
    ReferenceField,
    DateField
} from 'react-admin';

export const List = (props) => (
    <ListComp
        title='Transactions'
        {...props}
        bulkActionButtons={false}
        sort={{ field: 'created_at', order: 'DESC' }}
    >
        <Datagrid rowClick='show'>
            <DateField source='created_at' showTime />
            <TextField source='code' />
            <NumberField source='points' />
            <BooleanField source='is_withdrawn' label='Withdrawn' />
            <DateField source='withdrawn_at' showTime />
            <ReferenceField source='user_id' reference='users' link='show'>
                <TextField source='name' />
            </ReferenceField>
        </Datagrid>
    </ListComp>
);

const ShowTitle = (props) => {
    return <span>Transaction {`#${props.record.id}`}</span>;
};

export const Show = (props) => (
    <ShowComp title={<ShowTitle />} {...props}>
        <SimpleShowLayout>
            <DateField source='created_at' showTime />
            <TextField source='code' />
            <NumberField source='points' />
            <BooleanField source='is_withdrawn' label='Withdrawn' />
            <DateField source='withdrawn_at' showTime />
            <ReferenceField source='user_id' reference='users' link='show'>
                <TextField source='name' />
            </ReferenceField>
        </SimpleShowLayout>
    </ShowComp>
);
