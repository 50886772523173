import React, { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import UnpublishIcon from '@material-ui/icons/Restore';
import axios from 'axios';
import { Button, Confirm, useNotify, useRefresh } from 'react-admin';

interface IOwnProps {
    record?: any;
}

export const NewsPublishButton = ({ record }: IOwnProps) => {
    const { id, status, title_en } = record!;

    const [isLoading, setIsLoading] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();

    const handlePublish = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setIsLoading(true);
        e.stopPropagation();
        try {
            await axios.post(`/admin/news/${id}/publish`);
            notify(`News published successfully`);
            setIsLoading(false);
            refresh();
        } catch (err) {
            notify('Failed to publish the news');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUnpublish = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setIsLoading(true);
        e.stopPropagation();
        try {
            await axios.post(`/admin/news/${id}/unpublish`);
            notify(`News unpublished successfully`);
            setIsLoading(false);
            refresh();
        } catch (err) {
            notify('Failed to unpublish the news');
        } finally {
            setIsLoading(false);
        }
    };

    const openConfirmationModal = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.stopPropagation();
        setConfirmationModalOpen(true);
    };

    return (
        <Box alignItems='center' display='flex'>
            {isLoading || status === undefined ? (
                <CircularProgress />
            ) : status === 'draft' ? (
                <Box>
                    <Button
                        label='Publish'
                        color='secondary'
                        variant='contained'
                        onClick={openConfirmationModal}
                    >
                        {/* TODO: IconButton? */}
                        <PublishIcon />
                    </Button>
                    <Confirm
                        isOpen={confirmationModalOpen}
                        title={`Publish news "${title_en}"`}
                        content={`Are you sure you want to publish this news entry? A notification announcing the news will be pushed to all users.`}
                        onConfirm={handlePublish}
                        onClose={() => setConfirmationModalOpen(false)}
                    />
                </Box>
            ) : (
                <Box>
                    <Button
                        label='Unpublish'
                        color='secondary'
                        variant='contained'
                        onClick={openConfirmationModal}
                    >
                        <UnpublishIcon />
                    </Button>
                    <Confirm
                        isOpen={confirmationModalOpen}
                        title={`Unpublish news "${title_en}"`}
                        content={`Are you sure you want to unpublish this news entry? The notification about this news entry will be deleted from the notification feed.`}
                        onConfirm={handleUnpublish}
                        onClose={() => setConfirmationModalOpen(false)}
                    />
                </Box>
            )}
        </Box>
    );
};
