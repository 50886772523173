import React from 'react';
import { SaveButton, DeleteWithConfirmButton, Toolbar } from 'react-admin';
import styled from 'styled-components';

const StyledToolbar = styled(Toolbar)`
    display: flex;
    justify-content: space-between;
`;

type IOwnProps = {
    getDeleteConfirmContent?: (record: any) => string;
    getDeleteConfirmTitle?: (record: any) => string;
    withDelete?: boolean;
} & React.ComponentProps<typeof Toolbar>;

const EditToolbar = ({
    getDeleteConfirmContent,
    getDeleteConfirmTitle,
    withDelete = true,
    ...rest
}: IOwnProps) => (
    <StyledToolbar {...rest}>
        <SaveButton label='Save' redirect='show' submitOnEnter={true} />
        {withDelete ? (
            <DeleteWithConfirmButton
                confirmContent={
                    getDeleteConfirmContent &&
                    getDeleteConfirmContent(rest.record)
                }
                confirmTitle={
                    getDeleteConfirmTitle && getDeleteConfirmTitle(rest.record)
                }
            />
        ) : null}
    </StyledToolbar>
);

export default EditToolbar;
