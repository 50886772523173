import React, { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import axios from 'axios';
import { Button, Confirm, useNotify, useRefresh } from 'react-admin';

interface IOwnProps {
    record?: any;
}

export const BlockToggleButton = ({ record }: IOwnProps) => {
    const { id, blocked } = record!;
    const blockText = blocked ? 'unblock' : 'block';
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();

    const toggleBlocked = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setIsLoading(true);
        e.stopPropagation();
        try {
            await axios.put(`/admin/user/${id}/blocked`, {
                blocked: !blocked
            });

            notify(`User ${blocked ? 'unblocked' : 'blocked'} successfully`);
            setIsLoading(false);
            refresh();
        } catch (err) {
            notify('Failed to toggle user blocked');
        } finally {
            setIsLoading(false);
        }
    };

    const openConfirmationModal = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.stopPropagation();
        setConfirmationModalOpen(true);
    };

    return (
        <Box alignItems='center' display='flex'>
            {isLoading || blocked === undefined ? (
                <CircularProgress />
            ) : (
                <Button
                    label={blocked ? 'Unblock' : 'Block'}
                    color='secondary'
                    variant='contained'
                    onClick={openConfirmationModal}
                >
                    <BlockIcon />
                </Button>
            )}
            <Confirm
                isOpen={confirmationModalOpen}
                title={`${blocked ? 'Unblock' : 'Block'} user "${record.name}"`}
                content={`Are you sure you want to ${blockText} the user "${record.name}"?`}
                onConfirm={toggleBlocked}
                onClose={() => setConfirmationModalOpen(false)}
            />
        </Box>
    );
};
