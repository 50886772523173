import axios from 'axios';
import { errorHandler } from 'errorHandler';
import { stringify } from 'query-string';
import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    GET_MANY
} from 'react-admin';
import { baseUrl } from 'services/http';

export const user = async (type: string, params: any, resource: string) => {
    try {
        switch (type) {
            case GET_LIST: {
                const { search } = params.filter;
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: field,
                    order,
                    page,
                    perPage,
                    search
                };
                const url = `/user?${stringify(query)}`;
                const {
                    data: { items, total }
                } = await axios.get(url);

                const withGroups = await Promise.all(
                    items.map(async (x) => {
                        const {
                            data: { userGroups, _ }
                        } = await axios.get(`/user-group/${x.id}`);
                        return {
                            ...x,
                            groupNames:
                                (userGroups &&
                                    userGroups.map((x) => x.name).join(', ')) ||
                                'User'
                        };
                    })
                );

                return {
                    data: withGroups,
                    total: total
                };
            }
            case GET_ONE: {
                const {
                    data: {
                        id,
                        email,
                        name,
                        subscription,
                        image_url,
                        is_email_verified
                    }
                } = await axios.get(`/user/${params.id}`);
                const {
                    data: { userGroups, availableGroups }
                } = await axios.get(`/user-group/${id}`);
                const {
                    data: { userPermissions }
                } = await axios.get(`/user-permission/${id}`);
                const imageUrl = image_url ? `${baseUrl}${image_url}` : null;
                return {
                    data: {
                        id,
                        name,
                        email,
                        subscription,
                        imageUrl,
                        is_email_verified,
                        groups: userGroups,
                        availablegroups: availableGroups,
                        permissions: userPermissions
                    }
                };
            }
            case GET_MANY: {
                const rez = await Promise.all(
                    params.ids.map(async (idx) => {
                        const rez = await axios.get(`/user/${idx}`);
                        return rez.data;
                    })
                );

                return {
                    data: rez
                };
            }
            case UPDATE: {
                const {
                    id,
                    name,
                    email,
                    newPassword,
                    confirmPassword,
                    newGroups
                } = params.data;

                const formData = new FormData();
                formData.append('email', email);
                formData.append('name', name);
                newPassword && formData.append('password', newPassword);
                confirmPassword &&
                    formData.append('password_repeat', confirmPassword);

                await axios.put(`/user/${id}`, formData);

                let groups = newGroups && newGroups.map((p) => p.value);
                if (newGroups === null) groups = [];
                await axios.patch(`user/${id}`, { groups });

                return { data: params };
            }
            case CREATE: {
                const { email, name, newPassword, confirmPassword, group_id } =
                    params.data;

                const formData = new FormData();
                formData.append('email', email);
                formData.append('name', name);
                formData.append('password', newPassword);
                formData.append('password_repeat', confirmPassword);

                const { data } = await axios.post('/admin/register', formData);

                group_id &&
                    (await axios.put(`/user-group/${data.id}/${group_id}`));
                return { data };
            }
            case DELETE: {
                const { id } = params;
                const { data } = await axios.delete(`/user/${id}`);
                return { data };
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
    } catch (err) {
        errorHandler(err);
    }
};
