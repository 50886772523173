import React from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

const StyledCard = styled(Card)`
    padding: 20px;
`;

const CardHeading = styled(CardHeader)`
    text-align: center;
`;

export default () => {
    const user = localStorage.getItem('email');
    const title = `Welcome ${user}!`;
    return (
        <StyledCard>
            <CardHeading title={title} />
        </StyledCard>
    );
};
