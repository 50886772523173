import React from 'react';
import {
    AccountBox,
    Category,
    CheckBox,
    CheckBoxOutlined,
    ImageOutlined,
    PagesOutlined,
    Redeem,
    PolicyOutlined,
    ContactMail,
    Announcement,
    NotificationsActive,
    Receipt,
    Report
} from '@material-ui/icons';
import { Admin, Resource } from 'react-admin';
import dataProvider from 'Providers/dataProvider';
import authProvider from 'Providers/authProvider';
import {
    users,
    challengeCategories,
    challenges,
    posts,
    aboutContents,
    prize,
    policiesContents,
    contactInfo,
    news,
    customNotifications,
    challengeProposals,
    pointPurchases,
    flaggedPosts
} from './pages';
import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';
import { MainLayout } from 'UI/Layouts/Main';

const App = () => {
    return (
        <div className='App'>
            <Admin
                layout={MainLayout}
                dataProvider={dataProvider}
                dashboard={Dashboard}
                authProvider={authProvider}
                loginPage={Login}
            >
                {(permissions) => {
                    return (
                        (permissions['admin_read'] && [
                            <Resource name='images' />,
                            <Resource name='group' />,
                            <Resource name='permission' />,
                            <Resource name='settings' />,
                            <Resource
                                name='challenge_categories'
                                icon={Category}
                                list={challengeCategories.List}
                                show={challengeCategories.Show}
                                create={
                                    permissions['admin_write']
                                        ? challengeCategories.Create
                                        : null
                                }
                                edit={
                                    permissions['admin_write']
                                        ? challengeCategories.Edit
                                        : null
                                }
                                options={{ label: 'Categories' }}
                            />,
                            <Resource
                                name='challenges'
                                icon={CheckBox}
                                list={challenges.List}
                                show={challenges.Show}
                                create={
                                    permissions['admin_write']
                                        ? challenges.Create
                                        : null
                                }
                                edit={
                                    permissions['admin_write']
                                        ? challenges.Edit
                                        : null
                                }
                            />,
                            <Resource
                                name='challenge_proposals'
                                icon={CheckBoxOutlined}
                                list={challengeProposals.List}
                                show={challengeProposals.Show}
                                edit={
                                    permissions['admin_write']
                                        ? challengeProposals.Edit
                                        : null
                                }
                                options={{ label: 'Challenge Proposals' }}
                            />,
                            <Resource
                                name='posts'
                                icon={ImageOutlined}
                                list={posts.List}
                                show={posts.Show}
                            />,
                            <Resource
                                name='flagged_posts'
                                icon={Report}
                                list={flaggedPosts.List}
                                show={posts.Show}
                                options={{ label: 'Flagged Posts' }}
                            />,
                            <Resource
                                name='prizes'
                                icon={Redeem}
                                list={prize.List}
                                show={prize.Show}
                                create={
                                    permissions['admin_write']
                                        ? prize.Create
                                        : null
                                }
                                edit={
                                    permissions['admin_write']
                                        ? prize.Edit
                                        : null
                                }
                            />,
                            <Resource
                                name='news'
                                icon={Announcement}
                                list={news.List}
                                show={news.Show}
                                create={
                                    permissions['admin_write']
                                        ? news.Create
                                        : null
                                }
                                edit={
                                    permissions['admin_write']
                                        ? news.Edit
                                        : null
                                }
                                options={{ label: 'News' }}
                            />,
                            <Resource
                                name='custom_notification'
                                icon={NotificationsActive}
                                options={{ label: 'Notifications' }}
                                list={customNotifications.List}
                                show={customNotifications.Show}
                                create={
                                    permissions['admin_write']
                                        ? customNotifications.Create
                                        : null
                                }
                                edit={
                                    permissions['admin_write']
                                        ? customNotifications.Edit
                                        : null
                                }
                            />,
                            <Resource
                                name='point_purchase'
                                icon={Receipt}
                                options={{ label: 'Transactions' }}
                                list={pointPurchases.List}
                                show={pointPurchases.Show}
                            />,
                            <Resource
                                name='users'
                                icon={AccountBox}
                                list={users.List}
                                show={users.Show}
                                edit={
                                    permissions['admin_write']
                                        ? users.Edit
                                        : null
                                }
                                create={
                                    permissions['admin_write']
                                        ? users.Create
                                        : null
                                }
                            />,
                            <Resource
                                name='about_contents'
                                icon={PagesOutlined}
                                list={aboutContents.List}
                                show={aboutContents.Show}
                                edit={
                                    permissions['admin_write']
                                        ? aboutContents.Edit
                                        : null
                                }
                                options={{ label: 'About Vibelift' }}
                            />,
                            <Resource
                                name='policies_contents'
                                icon={PolicyOutlined}
                                list={policiesContents.List}
                                show={policiesContents.Show}
                                edit={
                                    permissions['admin_write']
                                        ? policiesContents.Edit
                                        : null
                                }
                                options={{ label: 'Policies & Terms' }}
                            />,
                            <Resource
                                name='contact_info'
                                options={{ label: 'Contacts' }}
                                icon={ContactMail}
                                show={contactInfo.Show}
                                edit={
                                    permissions['admin_write']
                                        ? contactInfo.Edit
                                        : null
                                }
                            />
                        ]) ||
                        []
                    );
                }}
            </Admin>
        </div>
    );
};

export default App;
