import axios from 'axios';
import { errorHandler } from 'errorHandler';
import { stringify } from 'query-string';
import { GET_LIST, GET_ONE, UPDATE } from 'react-admin';

export const aboutContent = async (
    type: string,
    params: any,
    resource: string
) => {
    try {
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: field,
                    order,
                    page,
                    perPage
                };
                const url = `/admin/about-page-contents?${stringify(query)}`;
                const {
                    data: { items, total }
                } = await axios.get(url);
                return {
                    data: items,
                    total
                };
            }
            case GET_ONE: {
                const rez = await axios.get(
                    `/admin/about-page-contents/${params.id}`
                );
                return {
                    data: rez.data
                };
            }
            case UPDATE: {
                const { id, content } = params.data;
                await axios.put(`/admin/about-page-contents/${id}`, {
                    content
                });
                return {
                    data: params
                };
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
    } catch (error) {
        errorHandler(error);
    }
};
