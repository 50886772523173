export const errorHandler = (error: any) => {
    if (error.response) {
        const {
            response: {
                data: {
                    error: { message }
                }
            }
        } = error;
        throw new Error(message || 'Internal server error');
    }
    throw error;
};
