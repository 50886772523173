import axios from 'axios';
import { stringify } from 'query-string';
import { GET_LIST, GET_ONE, CREATE, UPDATE } from 'react-admin';

export const setting = async (type: string, params: any, resource: string) => {
    switch (type) {
        case GET_LIST: {
            const { search } = params.filter;
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: field,
                order,
                page,
                perPage,
                search
            };
            const url = `/${resource}?${stringify(query)}`;
            const {
                data: { items, total }
            } = await axios.get(url);
            return {
                data: items,
                total: total
            };
        }
        case GET_ONE: {
            try {
                const {
                    data: { id, key, value }
                } = await axios.get(`/${resource}/${params.id}`);

                return {
                    data: {
                        id,
                        key,
                        value
                    }
                };
            } catch (err) {
                throw new Error('Server error');
            }
        }
        case UPDATE: {
            try {
                const { id, key, value, image } = params.data;

                const formData = new FormData();
                image && formData.append('file', image.rawFile);
                formData.append('key', key);
                formData.append('value', value);

                let query = `?key=${key}`;
                await axios.put(`/${resource}/${id}${query}`, formData);

                return { data: params };
            } catch (err) {
                throw new Error('Server error');
            }
        }
        case CREATE: {
            try {
                const { key, value } = params.data;

                const { data } = await axios.post(`${resource}`, {
                    key,
                    value
                });
                return { data };
            } catch (err) {
                if (err.response) {
                    throw new Error(err.response.data.error);
                }
                throw new Error('Server error');
            }
        }
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};
