import React, { useState } from 'react';
import {
    List as ListComp,
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
    DeleteWithConfirmButton,
    ImageField,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    TextInput,
    Button,
    Confirm,
    useNotify,
    useRefresh
} from 'react-admin';
import { Box, CircularProgress } from '@material-ui/core';
import ReportOff from '@material-ui/icons/ReportOff';
import axios from 'axios';

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label='User ID' source='user_id' reference='users' />
        <ReferenceInput
            label='Challenge name'
            source='challenge_id'
            reference='challenges'
            perPage={1000}
        >
            <AutocompleteInput
                optionText={(choice) => `${choice.title_en || 'any'}`}
            />
        </ReferenceInput>
    </Filter>
);

interface IOwnProps {
    record?: any;
}

const UnflagButton = ({ record }: IOwnProps) => {
    const { id, is_flagged } = record!;
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();

    const unflag = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setIsLoading(true);
        e.stopPropagation();
        try {
            await axios.put(`/admin/posts/${id}/unflag`);

            notify(`Post unflagged successfully.`);
            setIsLoading(false);
            refresh();
        } catch (err) {
            notify('Failed to unflag the post');
        } finally {
            setIsLoading(false);
        }
    };

    const openConfirmationModal = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.stopPropagation();
        setConfirmationModalOpen(true);
    };

    return (
        <Box alignItems='center' display='flex'>
            {isLoading || is_flagged === undefined ? (
                <CircularProgress />
            ) : (
                <Button
                    label={'Unflag'}
                    color='secondary'
                    variant='contained'
                    onClick={openConfirmationModal}
                >
                    <ReportOff />
                </Button>
            )}
            <Confirm
                isOpen={confirmationModalOpen}
                title={`Unflag post`}
                content={`Are you sure you want to unflag this post?`}
                onConfirm={unflag}
                onClose={() => setConfirmationModalOpen(false)}
            />
        </Box>
    );
};

export const List = (props) => (
    <ListComp
        {...props}
        bulkActionButtons={false}
        sort={{ field: 'flagged_at', order: 'ASC' }}
        filters={<PostFilter />}
    >
        <Datagrid rowClick='show'>
            <ImageField source='image_url' label='Post image' />
            <ReferenceField source='user_id' reference='users' link='show'>
                <TextField source='name' />
            </ReferenceField>
            <ReferenceField
                source='challenge_id'
                reference='challenges'
                link='show'
            >
                <TextField source='title_en' />
            </ReferenceField>
            <DateField source='created_at' showTime />
            <DateField source='flagged_at' showTime />

            {props.permissions && props.permissions['admin_write'] ? (
                <DeleteWithConfirmButton
                    record={props.record}
                    basePath={props.basePath}
                />
            ) : null}

            {props.permissions && props.permissions['admin_write'] ? (
                <UnflagButton />
            ) : null}
        </Datagrid>
    </ListComp>
);
