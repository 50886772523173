import {
    user,
    group,
    permission,
    setting,
    challengeCategory,
    challenge,
    aboutContent,
    prizes,
    policiesContent,
    news,
    contactInfo,
    customNotification,
    challengeProposal,
    pointPurchase,
    flaggedPost
} from './resources';
import { post } from './resources/post';

const actions = async (type: string, resource: string, params: any) => {
    switch (resource) {
        case 'users': {
            return user(type, params, resource);
        }
        case 'group': {
            return group(type, params, resource);
        }
        case 'permission': {
            return permission(type, params, resource);
        }
        case 'settings': {
            return setting(type, params, resource);
        }
        case 'challenge_categories': {
            return challengeCategory(type, params, resource);
        }
        case 'challenges': {
            return challenge(type, params, resource);
        }
        case 'posts': {
            return post(type, params, resource);
        }
        case 'about_contents': {
            return aboutContent(type, params, resource);
        }
        case 'prizes': {
            return prizes(type, params, resource);
        }
        case 'policies_contents': {
            return policiesContent(type, params, resource);
        }
        case 'contact_info': {
            return contactInfo(type, params, resource);
        }
        case 'news': {
            return news(type, params, resource);
        }
        case 'custom_notification': {
            return customNotification(type, params, resource);
        }
        case 'challenge_proposals': {
            return challengeProposal(type, params, resource);
        }
        case 'point_purchase': {
            return pointPurchase(type, params, resource);
        }
        case 'flagged_posts': {
            return flaggedPost(type, params, resource);
        }
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};

export default (type, resource, params) => {
    return actions(type, resource, params);
};
