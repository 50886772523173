import axios from 'axios';
import { errorHandler } from 'errorHandler';
import { stringify } from 'query-string';
import {
    CREATE,
    DELETE,
    GET_LIST,
    GET_MANY,
    GET_ONE,
    UPDATE
} from 'react-admin';

export const customNotification = async (
    type: string,
    params: any,
    resource: string
) => {
    try {
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: field,
                    order,
                    page,
                    perPage
                };
                const url = `/admin/notifications/custom?${stringify(query)}`;
                const {
                    data: { items, total }
                } = await axios.get(url);
                return {
                    data: items,
                    total: total
                };
            }
            case GET_ONE: {
                const rez = await axios.get(
                    `/admin/notifications/custom/${params.id}`
                );
                return {
                    data: rez.data
                };
            }
            case GET_MANY: {
                const rez = await Promise.all(
                    params.ids.map(async (idx) => {
                        const rez = await axios.get(
                            `/admin/notifications/custom/${idx}`
                        );

                        return rez.data;
                    })
                );

                return {
                    data: rez
                };
            }
            case CREATE: {
                const { content_lt, content_en } = params.data;
                const { data } = await axios.post(
                    '/admin/notifications/custom/',
                    {
                        content_lt,
                        content_en
                    }
                );
                return {
                    data
                };
            }
            case UPDATE: {
                const { content_lt, content_en, id } = params.data;

                await axios.put(`/admin/notifications/custom/${id}`, {
                    content_lt,
                    content_en,
                    id
                });

                return { data: params };
            }
            case DELETE: {
                const { id } = params;
                const { data } = await axios.delete(
                    `/admin/notifications/custom/${id}`
                );
                return {
                    data
                };
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
    } catch (error) {
        errorHandler(error);
    }
};
