import React from 'react';
import {
    List as ListComp,
    Show as ShowComp,
    Create as CreateComp,
    Datagrid,
    TextField,
    EditButton,
    NumberField,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    SelectInput,
    ImageField
} from 'react-admin';
import EditComp from 'UI/Molecules/EditForm';
import EditToolbar from 'UI/Molecules/EditToolbar';
import { makeStyles } from '@material-ui/core/styles';
import { IncreaseQuantity } from './IncreaseQuality';
import { ImageUpload } from '../../UI/Molecules/ImageUpload';

const validateNumber = (x: string) => {
    const number = parseInt(x);
    if (number <= 0) {
        return 'Number should be greater than 0';
    }

    return '';
};

const statusChoices = [
    { id: 'draft', name: 'draft' },
    { id: 'published', name: 'published' },
    { id: 'locked', name: 'locked' }
];

const useStyles = makeStyles({
    textArea: {
        width: '80%',
        '& textarea': {
            minHeight: '150px'
        }
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap'
    }
});

export const List = (props) => (
    <ListComp {...props} bulkActionButtons={false}>
        <Datagrid rowClick='show'>
            <TextField source='title_en' label='Title (EN)' />
            <NumberField source='price_in_points' />
            <NumberField label='Stock' source='stock_balance_in_units' />
            <TextField source='status' />
            {props.permissions && props.permissions['admin_write'] ? (
                <EditButton />
            ) : null}
        </Datagrid>
    </ListComp>
);

const ShowTitle = (props) => {
    return <span>Prize {`"${props.record.title_en}"`}</span>;
};

export const Show = (props) => {
    const classes = useStyles();
    return (
        <ShowComp title={<ShowTitle />} {...props}>
            <SimpleShowLayout>
                <TextField source='title_en' label='Title (EN)' />
                <TextField source='title_lt' label='Title (LT)' />
                <TextField source='description_en' label='Description (EN)' />
                <TextField source='description_lt' label='Description (LT)' />
                <NumberField source='price_in_points' />
                <NumberField label='Stock' source='stock_balance_in_units' />
                <TextField source='status' />
                <ImageField
                    source='images'
                    src='url'
                    title='desc'
                    className={classes.list}
                />
            </SimpleShowLayout>
        </ShowComp>
    );
};

export const Create = (props) => {
    const classes = useStyles();
    return (
        <CreateComp {...props}>
            <SimpleForm redirect='show'>
                <TextInput
                    source='title_en'
                    label='Title (EN)'
                    validate={[required()]}
                />
                <TextInput
                    source='title_lt'
                    label='Title (LT)'
                    validate={[required()]}
                />
                <TextInput
                    className={classes.textArea}
                    multiline
                    source='description_en'
                    label='Description (EN)'
                    validate={[required()]}
                />
                <TextInput
                    className={classes.textArea}
                    multiline
                    source='description_lt'
                    label='Description (LT)'
                    validate={[required()]}
                />
                <NumberInput
                    source='price_in_points'
                    validate={[required(), validateNumber]}
                />
                <NumberInput
                    source='stock_balance_in_units'
                    validate={[required(), validateNumber]}
                />

                <SelectInput
                    validate={[required()]}
                    emptyValue='draft'
                    source='status'
                    choices={statusChoices}
                />
                <ImageUpload
                    source='images'
                    multiple
                    label='Related pictures'
                    imagePreviewSource='url'
                />
            </SimpleForm>
        </CreateComp>
    );
};

const EditTitle = (props) => {
    return <span>Edit prize {`"${props.record.title_en}"`}</span>;
};

export const Edit = (props) => {
    const classes = useStyles();
    return (
        <EditComp title={<EditTitle />} props={props}>
            <SimpleForm
                toolbar={
                    <EditToolbar
                        getDeleteConfirmTitle={(record) =>
                            `Delete prize "${record.title_en}"`
                        }
                    />
                }
            >
                <TextField source='id' />
                <TextInput
                    source='title_en'
                    label='Title (EN)'
                    validate={[required()]}
                />
                <TextInput
                    source='title_lt'
                    label='Title (LT)'
                    validate={[required()]}
                />
                <TextInput
                    className={classes.textArea}
                    multiline
                    source='description_en'
                    label='Description (EN)'
                    validate={[required()]}
                />
                <TextInput
                    className={classes.textArea}
                    multiline
                    source='description_lt'
                    label='Description (LT)'
                    validate={[required()]}
                />
                <NumberInput
                    source='price_in_points'
                    validate={[required(), validateNumber]}
                />

                <SelectInput
                    validate={[required()]}
                    source='status'
                    choices={statusChoices}
                />

                <NumberField
                    source='stock_balance_in_units'
                    label='Current stock'
                />
                <IncreaseQuantity {...props} />
                <ImageUpload
                    source='images'
                    multiple
                    label='Related pictures'
                    imagePreviewSource='url'
                />
            </SimpleForm>
        </EditComp>
    );
};
