const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const validateUrl = (x: string) => {
    if (!x || x === '') {
        return '';
    }
    if (!x.match(urlRegex)) {
        return 'Enter a valid URL';
    }
    return '';
};
